.App {
  text-align: center;
  color: #333333;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10dp + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.margin-top--small {
  margin-top: 1rem;
  margin-bottom: -1rem;
}

.margin-top--small > div {
  flex-wrap: wrap;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

input[type='number'] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  

  border-width: 0px 0px 2px;
  border-color: #DADADA;
  font-size:  calc(19px + 4vmin);
  text-align: left;
  letter-spacing: 0px;
  color: #323A47;
  padding-bottom: 5px;

  text-align: center;
  justify-content: center;
}

input[type='number']:focus {
  /* border: none; */
  color: #0084F4;
  border-color: #0084F4;
  outline: none;
  
}

.view {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  width: 100%;
  margin-top: 9px;

}

.fullscreen{
  display: flex;
  /* flex-direction: column; */

  /* align-items: center; */
  justify-content: center;
  /* vertical-align: middle; */
  width: 100%;
  height: 100%;
  margin-top: 10%;

}

.container {
  display: flex;
  width: 100%;
  min-height: 10vh;
  height: 100%;
}

.xMark{
  /* font-size: xx-large; */
  font-size:  calc(19px + 4vmin);
  color: red;
}

.checkMark{
  /* font-size: xx-large; */
  font-size:  calc(19px + 4vmin);
  color: #0084F4;
}

.error-text {
  color: red;
}


@media only screen and (max-width: 678px) {
  .container {
    flex-wrap: wrap;
    flex-direction: column;
  }

  .side-bar {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .side-bar__segment--header {
    text-align: center;
  }

  .side-bar__segment--bottom {
    position: static !important;
    margin: 10px;
  }

  label {
    display: block;
    float: left;
    width: 120px;
  }

  .card {
    width: 100%;
    min-width: 120px !important;
    margin: 20px;
  }

  .side-bar__segment > label > input[type='number'],
  .side-bar__segment > label > input[type='text'] {
    width: 180px !important;
  }
}


/* .custom-input{
  border-width: 0px 0px 1px;
  border-color: #DADADA;
  font-size: 14px;
  text-align: left;
  letter-spacing: 0px;
  color: #323A47;
  padding-bottom: 5px;
} */

/* .App {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
} */

.fullscreen-v-centered {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center; 
  padding-left: 4dvw;
  padding-right: 4dvw;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grey {
  color: #5E5E5E;
  /* color: #0084F4; */
}

/* .view {
  margin-top: 20px;
} */

.user-output {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

/* .xMark,
.checkMark {
  font-size: 24px;
} */

.error-text {
  color: red;
  margin-top: 10px;
}

.centered {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.v-centered {
  display: flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  flex-direction: column;

  /* height: 50vh; */
  flex-direction: column;
  margin-bottom: 10vh;
  margin-top: 15dvh;
}


h2 {
  margin-bottom: 20dp;
}

a {
  color: #0084F4;
  cursor: pointer;
  text-decoration: underline;
}

.close-button-container {
  display: flex;
  justify-content: flex-end; 
  padding: 10px; 
  /* pointer-events: none; */
  /* z-index:9; */


}


.close-button {
  position: absolute;  
  top: -15px;           
  right: 15px;         
  width: 30px;         
  height: 30px;        
  background-color: #bcbcbc;  
  color: white;        
  border: none;        
  border-radius: 50%;  
  display: flex;       
  align-items: center; 
  justify-content: center; 
  cursor: pointer;     
  font-size: calc(16px + 1vmin);     
  z-index:10;
}

.close-button:hover {
  background-color: #555;  /* Darker grey on hover */
}
